import React, {FC} from "react";

import Game from "../components/game";
import StartPage from "../components/start-page";

import {useGame} from "../state";
import {Pages} from "../state/constants";
import "./app.scss";

const App: FC<{}> = () => {
    const { state, setPage, setLives} = useGame();

    const updateAfterGameOver = () => {
        if (state.lives === 1) {
            setPage(Pages.start)
            return;
        }

        setLives(state.lives - 1);
    }

    const render = () => {
        if (state.page === Pages.start) {
            return (
                <StartPage start={() => {
                    setPage(Pages.game);
                    setLives(3);
                }} />
            )
        }

        return (
            <div className={'game game__section'}>
                <Game
                    liveCount={state.lives}
                    updatePage={updateAfterGameOver}
                    onCancel={() => setPage(Pages.start)}
                />
            </div>
        );
    }

    return (
        <>
            <audio id={'sound'} preload={'auto'} src={"audio/sound.mp3"} />
            <img src={`images/sprite-4.svg`} id={'bird'} style={{ position: 'fixed', left: 9999 }}  alt=""/>
            <img src={`images/bg-games4.png`} id={'bg'}  style={{ position: 'fixed', left: 9999 }} alt=""/>
            {render()}
        </>
    );



}

export default App;