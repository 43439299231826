import {MutableRefObject, useEffect, useRef, useState} from "react";
import {gameState} from "../../state/constants";
import {Bg, Bird, Pipes} from "./game-items";
import {IObjectGame} from "../../shared/types";

export const useOnDraw = () => {
    const imageBg = document.getElementById('bg') as HTMLImageElement;
    const sprite = document.getElementById('bird') as HTMLImageElement;
    const sound = document.getElementById('sound') as HTMLAudioElement;
    /** ссылка на канвас */
    const canvasRef = useRef<HTMLCanvasElement>() as MutableRefObject<HTMLCanvasElement>;

    const [frame, setFrame] = useState(0);
    const [time, setTime] = useState(0);
    const [score, setScore] = useState(0);

    const [game, setGame] = useState(gameState.playWithoutGravity);

    const [pipes, setPipes] = useState<IObjectGame>();
    const [bg, setBg] = useState<IObjectGame>();
    const [bird, setBird] = useState<IObjectGame>();
    const [play, setPlay] = useState(false);

    useEffect(() => {
        document.addEventListener('keypress', handlePressEnter)

        return () => {
            document.removeEventListener('keypress', handlePressEnter)
        }
    }, [game]);

    const handlePressEnter = (event: any) => {


        // if (![gameState.ready, gameState.playWithoutGravity, gameState.play].includes(game)) {
        //     return;
        // }
        console.log(event, game);

        if (event.key === 'Enter') {
            console.log(event);
            handleClick();
        }
    }

    useEffect(() => {
        const canvas = canvasRef.current;

        if (!imageBg || !sprite) {
            return;
        }

        if (game === gameState.instruction || game === gameState.playWithoutGravity) {
            init(canvas);

        }

    }, [imageBg, sprite, game]);

    useEffect(() => {
        loop();
    }, [frame, game]);

    const init = (canvas: HTMLCanvasElement) => {
        const background = new Bg(canvas, imageBg);
        const newBird = new Bird(canvas, sprite);
        const newPipes = new Pipes(canvas, sprite, newBird);
        setBird(newBird);
        setBg(background);
        setPipes(newPipes);
    };

    const loop = () => {
        const canvas = canvasRef.current;
        const cont = canvasRef.current.getContext('2d') as CanvasRenderingContext2D;

        render(cont, canvas);
        update();

        if (game !== gameState.play && game !== gameState.playWithoutGravity) {
            return;
        }

        if (!play) {
            setPlay(true);
            sound.play()
        }

        setTimeout(() => {
            setFrame(frame + 1);

            if ((frame + 1)%50 === 0) {
                if (time === 1) {
                    setGame(gameState.ready);
                    setTimeout(() => {
                        setGame(gameState.playWithoutGravity);
                    }, 0)
                }
                if (time > 0) {
                    setTime(time - 1);
                }

            }



        }, 10)

    }

    const render = (context: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
        context.fillRect(0, 0, canvas.width, canvas.height)

        bg?.render({ context });
        bird?.render({ context });
        pipes?.render({ context });
    }

    const update = () => {
        bg?.position({ game });
        bird?.position({ game, gameOver, time });
        pipes?.position({ game, frame, gameOver, time, scoreUp })

    }

    const getCanvasRef = (ref: HTMLCanvasElement) => {
        if (!ref) {
            return;
        }

        canvasRef.current = ref;
    }

    const handleClick = () => {
        console.log(1, game);
        if (game === gameState.instruction) {
            setGame(gameState.playWithoutGravity);
        }
        if (game === gameState.ready) {
            setGame(gameState.playWithoutGravity);
        }
        if (game === gameState.playWithoutGravity) {
            setGame(gameState.play);
            bird?.flap && bird?.flap();
        }
        if (game === gameState.play) {
            // тут будем прыгать
            bird?.flap && bird?.flap()
        }
    };

    const gameOver = (reset?: boolean) => {
        if (reset) {
            setGame(gameState.ready);
            return;
        }

        sound.pause()
        sound.currentTime = 0;
        setPlay(false);

        setGame(gameState.gameOver);
    }

    const scoreUp = () => {
        setScore(score + 1);
    }

    return {
        getCanvasRef,
        handleClick,
        score,
        time,
        game,
        setGame,
    };
}