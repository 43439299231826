import React, {FC, useEffect} from "react";
import Button from "../../components/button";
import Translate from "../../components/translate";

const StartPage: FC<{ start(): void }> = ({ start }) => {
    useEffect(() => {
        document.addEventListener('keypress', handleStart);

        return () => {
            document.removeEventListener('keypress', handleStart);
        }
    }, []);

    const handleStart = (event: any) => {
        console.log(event.key);
        if (event.key === 'Enter') {
            start()
        }
    }

    return (
        <div className='game'>
            <section className='game__start game__section'>
                <div className="game__screen game__container">
                    <div className={'game__start-logo'} />
                    <Button borderWhite onClick={start}><Translate text={'START'} /></Button>
                </div>
            </section>
        </div>
    );
}

export default StartPage;
