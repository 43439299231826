import React, {FC, useMemo} from "react";
import {useOnDraw} from "./hook";
import {gameState} from "../../state/constants";
import GameOver from "../game-over";
import {getFiveNumbers} from "../../shared/utils/utils";
import Instruction from "../instruction";

interface IGameProps {
    readonly liveCount: number;
    updatePage(): void;
    onCancel(): void;
}
const Game: FC<IGameProps> = (props) => {
    const {
        getCanvasRef,
        handleClick,
        score,
        game,
        time,
        setGame,
    } = useOnDraw();

    const updateGameAfterGameOver = () => {
        props.updatePage();
        setGame(gameState.playWithoutGravity);
    }

    const getLives = useMemo(() => {
        if (time > 0) return (
            <div className={'game__score-current'}>
                TEST
            </div>
        );

        return (
            <Lifes lifes={props.liveCount} />
        );
    }, [props.liveCount, time]);

    const getScore = useMemo(() => {
        if (game === gameState.gameOver || time !== 0) return;

        return (
            <div className={'game__score-current'}>
                {getFiveNumbers(score)}
            </div>
        );
    }, [score, time]);

    const getTimes = useMemo(() => {
        if (time === 0) return;

        return (
            <div className={'game__time'}>
                {time}
            </div>
        );
    }, [time]);

    return (
        <>
            <div className={'game__info'}>
                {getLives}
                {getTimes}
                {getScore}
            </div>
            <canvas
                id='game'
                className='game__canvas'
                ref={getCanvasRef}
                width={window.innerWidth}
                height={window.innerHeight}
                onClick={handleClick}
            />
            {game === gameState.gameOver && (
                <GameOver
                    count={score}
                    lives={props.liveCount}
                    onNext={updateGameAfterGameOver}
                />
            )}
            {game === gameState.instruction && (<Instruction
                onNext={() => setGame(gameState.playWithoutGravity)}
            />)}
        </>
    );
}

export default Game;

export const Lifes: FC<{ lifes: number }> = ({ lifes }) => {
    const livesMap = Array.from({ length: 3 }, (v, i) => {
        if (i + 1 > lifes) {
            return 'dead' + i
        }

        return i.toString();
    })

    return (
        <div className={'game__lives'}>
            {livesMap.map(i => (<div key={`live__${i}`} className={`game__hearth${i.match('dead') ? ' game__hearth--dead' : ''}`} />))}
        </div>
    );
}