import React, {FC, useEffect} from "react";
import Button from "../button";
import Translate from "../translate";

interface IInstructionProps {
    onNext(): void;
}
const Instruction: FC<IInstructionProps> = (props) => {
    useEffect(() => {
        document.addEventListener('keypress', handleStart);

        return () => {
            document.removeEventListener('keypress', handleStart);
        }
    }, []);

    const handleStart = (event: any) => {
        console.log(event.key);
        if (event.key === 'Enter') {
            props.onNext()
        }
    }

    return (
        <div className="game__layout">
            <div className="game__data game__popup">
                <div className="game__instruction-top">
                    <div className="game__title" >
                        <Translate text={'Hoe spelen'} />
                    </div>
                </div>
                <div className={'game__instruction'} />
                <div className="game__text">
                    <Translate text={'Klik op het scherm, hou je papegaai in de lucht & ontwijk de obstakels op punten te scoren.'} />
                </div>
                <Button borderMain onClick={props.onNext}>
                    <Translate text={'START'} /></Button>
            </div>
        </div>
    );
}

export default Instruction;