import React from 'react';
import {ruToKeyDictionary} from "./dictionaries";
import {ITranslateProps, TranslateType} from "./types";
import {Lang} from "../../state/constants";

export const translateFunc = (text: TranslateType, lang: Lang) => {
    return ruToKeyDictionary[text][lang];
}
const Translate: React.FC<ITranslateProps> = ({ text}) => {
    const lang = Lang.NL;

    return(<>{translateFunc(text, lang)}</>);
}

export default Translate;
