import {Lang} from "../../state/constants";

export const ruToKeyDictionary = {
  /** ---------------------------------- header / footer */
  ["Lang"]: {
    [Lang.NL]: "Taal",
    [Lang.FR]: "Langue",
  },
  ["hello"]: {
    [Lang.NL]: "Hallo",
    [Lang.FR]: "Bonjour",
  },
  ["Voorwaarden"]: {
    [Lang.NL]: "Voorwaarden",
    [Lang.FR]: "Conditions",
  },
  ["Privacy policy"]: {
    [Lang.NL]: "Privacy Policy",
    [Lang.FR]: "Privacy Policy",
  },
  ["Cookie policy"]: {
    [Lang.NL]: "Cookie policy",
    [Lang.FR]: "Cookie policy",
  },
  ["Wedstrijdreglement"]: {
    [Lang.NL]: "Wedstrijdreglement",
    [Lang.FR]: "Règlement de concours",
  },
  /** ---------------------------------- home page */
  ["DOE MEE"]: {
    [Lang.NL]: "DOE MEE",
    [Lang.FR]: "PARTICIPEZ",
  },
  ["Deze website gebruikt cookies voor een optimale ervaring."]: {
    [Lang.NL]: "Deze website gebruikt cookies voor een optimale ervaring.",
    [Lang.FR]: "Ce site web utilise des cookies pour une expérience optimale.",
  },
  ["Kies je prijs & speel!"]: {
    [Lang.NL]: "Kies je prijs & speel!",
    [Lang.FR]: "Choisissez votre prix & jouez !",
  },
  ["SPEEL"]: {
    [Lang.NL]: "SPEEL",
    [Lang.FR]: "JOUEZ",
  },
  ["ACCEPTEER ALLE COOKIES"]: {
    [Lang.NL]: "ACCEPTEER ALLE COOKIES",
    [Lang.FR]: "JOUEZ",
  },
  ["WEIGER"]: {
    [Lang.NL]: "WEIGER",
    [Lang.FR]: "refuser",
  },

  /** ----------------------------------- code page */
  ["Heb je een code?"]: {
    [Lang.NL]: "Heb je een code?",
    [Lang.FR]: "Vous avez un code ?",
  },
  ["De Croky Naturel, Paprika, Bolognese & Pickles met een Croky-promostrip bevatten een speelcode binnenin de zak. Met deze code krijg je 3 levens voor het gekozen level."]: {
    [Lang.NL]: "De Croky Naturel, Paprika, Bolognese & Pickles met een Croky-promostrip bevatten een speelcode binnenin de zak. Met deze code krijg je 3 levens voor het gekozen level. ",
    [Lang.FR]: "Les sachets Croky Naturel, Paprika, Bolognese et Pickles avec un bandeau promo Croky contiennent un code de jeu. Ce code vous donne 3 vies pour le niveau choisi.",
  },
  ["Haal je Croky zak en verhoog je kansen!"]: {
    [Lang.NL]: "Haal je Croky zak en verhoog je kansen!",
    [Lang.FR]: "Allez chercher votre sachet Croky et augmentez vos chances !",
  },
  ["ACTIVEER & SPEEL"]: {
    [Lang.NL]: "ACTIVEER & SPEEL",
    [Lang.FR]: "ACTIVEZ & JOUEZ",
  },
  ["Spelen zonder code"]: {
    [Lang.NL]: "Spelen zonder code",
    [Lang.FR]: "Jouer sans code",
  },
  ["Heb je geen speelcode? Dan kan je het spel 1x spelen en dat 1x per week."]: {
    [Lang.NL]: "Heb je geen speelcode? Dan kan je het spel 1x spelen en dat 1x per week.",
    [Lang.FR]: "Vous n'avez pas de code de jeu ? Vous ne pouvez alors jouer que 1 x par semaine.",
  },
  ["Haal je Croky zak met speelcode nu en verhoog je kansen op een prijs!"]: {
    [Lang.NL]: "Haal je Croky zak met speelcode nu en verhoog je kansen op een prijs!",
    [Lang.FR]: "Allez tout de suite chercher votre sachet Croky avec un code de jeu et augmentez vos chances de gagner !",
  },
  ["IK SPEEL ZONDER CODE"]: {
    [Lang.NL]: "IK SPEEL ZONDER CODE",
    [Lang.FR]: "JE JOUE SANS CODE",
  },
  ["Controleer je mailbox"]: {
    [Lang.NL]: "Controleer je mailbox",
    [Lang.FR]: "Vérifiez votre boîte aux lettres",
  },
  ["Succes"]: {
    [Lang.NL]: "Succes",
    [Lang.FR]: "Succès",
  },
  ["Error"]: {
    [Lang.NL]: "Error",
    [Lang.FR]: "Erreur",
  },
  ["Onjuist(e) gebruikersnaam en/of wachtwoord"]: {
    [Lang.NL]: "Onjuist(e) gebruikersnaam en/of wachtwoord",
    [Lang.FR]: "Nom d'utilisateur et/ou mot de passe incorrect",
  },
  ["Vul de verplichte velden in"]: {
    [Lang.NL]: "Vul de verplichte velden in",
    [Lang.FR]: "Veuillez remplir les champs obligatoires",
  },
  /** ----------------------------------- instruction popup */
  ["Hoe spelen"]: {
    [Lang.NL]: "15 sec oefenen",
    [Lang.FR]: "15 secondes d'entraînement",
  },
  ["START"]: {
    [Lang.NL]: "START",
    [Lang.FR]: "PARTAGEZ",
  },
  ["Klik op het scherm, hou je papegaai in de lucht & ontwijk de obstakels op punten te scoren."]: {
    [Lang.NL]: "Klik op het scherm, hou je papegaai in de lucht & ontwijk de obstakels op punten te scoren.",
    [Lang.FR]: "Cliquez sur l’écran, gardez votre perroquet en l’air et évitez les obstacles pour marquer des points.",
  },
  /** ----------------------------------- game over */
  ["5"]: {
    [Lang.NL]: "",
    [Lang.FR]: "",
  },
  /** ----------------------------------- login */
  ["Maak je account"]: {
    [Lang.NL]: "Maak je account",
    [Lang.FR]: "Créez votre compte",
  },
  ["Gebruikersnaam"]: {
    [Lang.NL]: "Gebruikersnaam",
    [Lang.FR]: "Nom d'utilisateur",
  },
  ["Wachtwoord"]: {
    [Lang.NL]: "Wachtwoord",
    [Lang.FR]: "Mot de passe",
  },
  ["Naam"]: {
    [Lang.NL]: "Naam",
    [Lang.FR]: "Nom",
  },
  ["Email adres"]: {
    [Lang.NL]: "Email adres",
    [Lang.FR]: "Adresse e-mail",
  },
  ["Land"]: {
    [Lang.NL]: "Land",
    [Lang.FR]: "Pays",
  },
  ["BEVESTIG"]: {
    [Lang.NL]: "BEVESTIG",
    [Lang.FR]: "CONFIRMEZ",
  },
  ["bevestigen"]: {
    [Lang.NL]: "bevestigen",
    [Lang.FR]: "confirmer",
  },
  ["Wachtwoord opnieuw instellen"]: {
    [Lang.NL]: "wachtwoord opnieuw verzenden",
    [Lang.FR]: "Renvoyer le mot de passe",
  },
  ["Ik ga akkoord met de algemene voorwaarden en het wedstrijdreglement"]: {
    [Lang.NL]: "Ik ga akkoord met de algemene voorwaarden en het wedstrijdreglement",
    [Lang.FR]: "J'accepte les conditions générales et le règlement du concours",
  },
  ["Ja, ik wil als eerste op de hoogte gehouden worden van andere acties of promoties."]: {
    [Lang.NL]: "Ja, ik wil als eerste op de hoogte gehouden worden van andere acties of promoties.",
    [Lang.FR]: "Oui, je veux être le premier à être informé des autres promotions ou actions.",
  },
  ["Wachtwoord vergeten?"]: {
    [Lang.NL]: "Wachtwoord vergeten?",
    [Lang.FR]: "oublié votre mot de passe ?",
  },
  ["Al een account? Meld je aan"]: {
    [Lang.NL]: "Al een account? Meld je aan",
    [Lang.FR]: "Vous avez déjà un compte ? Connectez-vous",
  },
  ["Wachtwoord herstel"]: {
    [Lang.NL]: "Wachtwoord herstel",
    [Lang.FR]: "Récupération du mot de passe",
  },
  ["Thank you. Your email was successfully confirmed."]: {
    [Lang.NL]: "Bedankt. Je e-mail is succesvol bevestigd.",
    [Lang.FR]: "Nous vous remercions. Votre courriel a été confirmé avec succès.",
  },
  ["Netherlands"]: {
    [Lang.NL]: "Nederland",
    [Lang.FR]: "Pays-Bas",
  },
  ["Belgium"]: {
    [Lang.NL]: "België",
    [Lang.FR]: "Belgique",
  },
  /** ----------------------------------- scorebord */
  ["Elke week"]: {
    [Lang.NL]: "Elke week ",
    [Lang.FR]: "",
  },
  ["winnaars. Afsluit elke zondag om 00u."]: {
    [Lang.NL]: " winnaars. Afsluit elke zondag om 00u.",
    [Lang.FR]: " gagnants chaque semaine. Fin du concours tous les dimanches à minuit.",
  },
  ["BEWAAR JOUW SCORE"]: {
    [Lang.NL]: "BEWAAR JOUW SCORE",
    [Lang.FR]: "SAUVEGARDEZ VOTRE SCORE",
  },
  ["start pagina"]: {
    [Lang.NL]: "start pagina",
    [Lang.FR]: "page d'accueil",
  },
  ["Scorebord"]: {
    [Lang.NL]: "Scorebord",
    [Lang.FR]: "Tableau des résultats",
  },
  ["PROBEER OPNIEUW"]: {
    [Lang.NL]: "PROBEER OPNIEUW",
    [Lang.FR]: "RÉESSAYER",
  },
  ["Home"]: {
    [Lang.NL]: "Home",
    [Lang.FR]: "Accueil",
  },
  ["Je hebt je gratis leven al gebruikt"]: {
    [Lang.NL]: "Je hebt je gratis leven al gebruikt",
    [Lang.FR]: "Vous avez déjà utilisé votre vie libre",
  },
  ["Koop Croky en krijg 3 nieuwe levens dankzij de unieke speelcode aan de binnenzijde van de Croky zak"]: {
    [Lang.NL]: "Koop Croky en krijg 3 nieuwe levens dankzij de unieke speelcode aan de binnenzijde van de Croky zak",
    [Lang.FR]: "Achetez Croky et obtenez 3 nouvelles vies grâce au code de jeu unique qui se trouve à l'intérieur du sac de Croky.",
  },
  ["BEGREPEN"]: {
    [Lang.NL]: "BEGREPEN",
    [Lang.FR]: "MISE À JOUR",
  },
  ["You have"]: {
    [Lang.NL]: "je hebt nog",
    [Lang.FR]: "il vous reste",
  },
  ["lives left"]: {
    [Lang.NL]: "levens over",
    [Lang.FR]: "vies",
  },
  /** ----------------------------------- errors */
  ["insufficient-lives-to-save-a-record"]: {
    [Lang.NL]: "Koop Croky en krijg 3 nieuwe levens dankzij de unieke speelcode aan de binnenzijde van de Croky zak",
    [Lang.FR]: "Achetez Croky et obtenez 3 nouvelles vies grâce au code de jeu unique qui se trouve à l'intérieur du sac de Croky.",
  },
  ["invalid-credentials"]: {
    [Lang.NL]: "Probeer opnieuw of klik op Wachtwoord vergeten om dit te resetten",
    [Lang.FR]: "Veuillez réessayer ou cliquez sur Mot de passe oublié pour le réinitialiser",
  },
  ["only-anon-user-can-sign-up"]: {
    [Lang.NL]: "alleen anonieme gebruiker kan zich aanmelden",
    [Lang.FR]: "seul l'utilisateur anon peut s'inscrire",
  },
  ["username-already-exists"]: {
    [Lang.NL]: "gebruikersnaam bestaat al",
    [Lang.FR]: "le nom d'utilisateur existe déjà",
  },
  ["email-already-exists"]: {
    [Lang.NL]: "e-mail bestaat al",
    [Lang.FR]: "l'email existe déjà",
  },
  ["email-not-found"]: {
    [Lang.NL]: "e-mail niet gevonden",
    [Lang.FR]: "email introuvable",
  },
  ["user-not-found"]: {
    [Lang.NL]: "gebruiker niet gevonden",
    [Lang.FR]: "utilisateur introuvable",
  },
  ["token-already-used"]: {
    [Lang.NL]: "Koop Croky en krijg 3 nieuwe levens dankzij de unieke speelcode aan de binnenzijde van de Croky zak",
    [Lang.FR]: "Achetez Croky et obtenez 3 nouvelles vies grâce au code de jeu unique qui se trouve à l'intérieur du sac de Croky.",
  },
  ["already-used"]: {
    [Lang.NL]: "Koop Croky en krijg 3 nieuwe levens dankzij de unieke speelcode aan de binnenzijde van de Croky zak",
    [Lang.FR]: "Achetez Croky et obtenez 3 nouvelles vies grâce au code de jeu unique qui se trouve à l'intérieur du sac de Croky.",
  },
  ["token-expired"]: {
    [Lang.NL]: "token verlopen",
    [Lang.FR]: "jeton expiré",
  },
  ["invalid-token"]: {
    [Lang.NL]: "ongeldig token",
    [Lang.FR]: "code invalide",
  },
  ["unauthenticated"]: {
    [Lang.NL]: "niet-geverifieerd",
    [Lang.FR]: "non authentifié",
  },
  ["invalid-code"]: {
    [Lang.NL]: "ongeldige code",
    [Lang.FR]: "code invalide",
  },
  ["username-contains-offensive-word"]: {
    [Lang.NL]: "gebruikersnaam bevat aanstootgevend woord",
    [Lang.FR]: "Le nom d'utilisateur contient des mots offensants",
  },
  ["Deze code is al gebruikt"]: {
    [Lang.NL]: "Deze code is al gebruikt",
    [Lang.FR]: "Ce code a déjà été utilisé",
  },
  /**------------------------------ errors */
  [" "]: {
    [Lang.NL]: "Deze code is al gebruikt",
    [Lang.FR]: "Partybox",
  },

}
