import {gameState} from "../../state/constants";
import {IPipe, IPropsRender} from "../../shared/types";
import {randomize} from "../../shared/utils/utils";

export class Bg {
    private imageWidth: number;
    private imageHeight: number;
    private width: number;
    private height: number;
    private image: HTMLImageElement;
    private x: number;
    private y: number;

    constructor(canvas: HTMLCanvasElement, image: HTMLImageElement) {

       this.imageWidth = image.width;
       this.imageHeight = image.height;

       this.width = image.width * 1.4;
       this.height = canvas.height;
       this.image = image;
       this.x = 0;
       this.y = 0;
    }

    dx = .5;
    render({ context }: IPropsRender) {
        if (!context) return;

        context.drawImage(
            this.image, // само изображение
            0,  // координаты верхней левой точки изображения, откуда вырезать (по оси X)
            0,  // координаты верхней левой точки изображения, откуда вырезать (по оси Y)
            this.imageWidth, // ширина вырезаемого изображения
            this.imageHeight,
            this.x, // положение на канвасе
            this.y, // положение на канвасе
            this.width, // размер на канвасе
            this.height
        );
        context.drawImage(this.image, 0, 0, this.imageWidth, this.imageHeight, this.x + this.width, this.y, this.width, this.height)
        context.drawImage(this.image, 0, 0, this.imageWidth, this.imageHeight, this.x + this.width*2, this.y, this.width, this.height)
    }
    flap() {

    }

    position({ game }: IPropsRender) {
        if (game === gameState.instruction || game === gameState.ready) {
            this.x = 0;
        }
        if (game === gameState.play || game === gameState.playWithoutGravity) {
            this.x = (this.x-this.dx) % (this.width)
        }
    }
}

export class Bird {
    private imageWidth: number;
    private imageHeight: number;
    private image: HTMLImageElement;
    private canvas: HTMLCanvasElement;
    public x: number;
    public y: number;
    public velocity: number;
    constructor(canvas: HTMLCanvasElement, image: HTMLImageElement) {
        this.imageWidth = 80;
        this.imageHeight = 58;

        this.image = image;
        this.canvas = canvas;

        this.x = 320;
        this.y = canvas.height / 2;
        this.velocity = 0;
    }

    fr = 0;
    w = 110;
    h = 70;
    animation = [
        {imgX: 0, imgY: 0},
        {imgX: 80, imgY: 0},
        {imgX: 163, imgY: 0},
    ];
    fly = 5;
    gravity = .22;


    render = ({ context }: IPropsRender) => {
        if (!context) return;

        let bird = this.animation[this.fr];
        context.save();
        context.drawImage(this.image, bird.imgX, bird.imgY, this.imageWidth, this.imageHeight, this.x-this.w/2,this.y-this.h/2,this.w,this.h)
        context.restore();
    }

    flap = () => {
        this.velocity = -this.fly;
        this.gravity = .22;
    }
    position = ({ game, gameOver, time }: IPropsRender) => {
        if (game === gameState.ready || game === gameState.playWithoutGravity) {
            this.y = this.canvas.height / 2;
            this.fr = 0;
        }
        else {
            /** машем крыльями */
            if (this.velocity < -3) {
                this.fr = 2;
            } else if (this.velocity < -1) {
                this.fr = 1;
            } else {
                this.fr = 0;
            }

            /** падаем*/
            this.velocity = this.velocity + this.gravity
            this.y += this.velocity

            /** если координаты птицы больше высоты экрана - птица упала */
            if (this.y+this.h/2 >= this.canvas.height) {
                this.y = this.canvas.height / 2
                this.velocity = 0;
                this.gravity = 0;
                gameOver && gameOver(time !== 0)
            }

            /** наоборот птица вознеслась */
            if (this.y-this.h/2 <= 0) {
                this.y = this.canvas.height / 2
                this.velocity = 0;
                this.gravity = 0;
                gameOver && gameOver(time !== 0)
            }
        }
    }
}

export class Pipes {
    private image: HTMLImageElement;
    private canvas: HTMLCanvasElement;
    private bird: Bird;
    private rangeY: { min: number; max: number }[];
    private pipeGenerator: IPipe[];
    constructor(canvas: HTMLCanvasElement, image: HTMLImageElement, bird: Bird) {
        this.image = image;
        this.canvas = canvas;
        this.bird = bird;
        this.rangeY = [
            {
                min: 100,
                max: 350,
            },
            {
                min: 350,
                max: 500,
            },
            {
                min: 500,
                max: canvas?.height - 100,
            },
        ];
        this.pipeGenerator = [
            // {
            //     x: canvas.width - 3*360,
            //     y:  randomize(180, 350),
            //     xGap: 180,
            // },
            {
                x: canvas.width - 2*360,
                y:  randomize(500,  canvas?.height - 100),
                xGap: 180,
            },
            {
                x: canvas.width - 360,
                y:  randomize(350, 500),
                xGap: 160,
            }
        ];
    }

    imageWidth = 75;
    imageHeight = 58;

    width = 90;
    height = 58;

    w = 55;
    h = 55;
    gap = 220;
    dx = 2; // на сколько сдвигать джойстики


    range = 0;
    rate = 10;




    reset() {
        this.pipeGenerator = [];
    }

    render({ context }: IPropsRender) {
        if (!context) return;

        for (let i = 0; i < this.pipeGenerator.length; i++) {
            let pipe = this.pipeGenerator[i]

            context.drawImage(
                this.image, 0, 58, this.imageWidth, this.imageHeight,
                pipe.x, pipe.y - pipe.xGap, this.width,this.height
            )

            context.drawImage(
                this.image, 0, 58, this.imageWidth, this.imageHeight,
                pipe.x, pipe.y, this.width, this.height
            );
            context.drawImage(
                this.image, 0, 58, this.imageWidth, this.imageHeight,
                pipe.x , pipe.y + pipe.xGap, this.width,this.height
            )
        }
    }

    position({ game, frame = 0, gameOver, scoreUp, time = 0 }: IPropsRender) {
        if (game !== gameState.play && game !== gameState.playWithoutGravity) {
            this.pipeGenerator = []
            return
        }
        else {
            if (frame%800 === 0) {
                if (this.dx === 2) {
                    this.dx = randomize(3, 4);
                } else {
                    this.dx = 2
                }
            }

            if (frame%180 === 0) {
                this.range = this.range === 2 ? 0 : this.range + 1;
                const min = this.rangeY[this.range].min;
                const max = this.rangeY[this.range].max;
                const coord = {
                    x: this.canvas.width,
                    y:  randomize(min, max),
                    xGap: randomize(120, 250),
                }

                this.pipeGenerator.push(coord);
            }
            for (let i = 0; i < this.pipeGenerator.length; i++) {

                let pg = this.pipeGenerator[i]
                let bird = {
                    leftX: this.bird.x - this.bird.w/2,
                    rightX: this.bird.x + this.bird.w/2,
                    topY: this.bird.y - this.bird.h/2 - 5,
                    bottomY: this.bird.y + this.bird.h/2 - 2,
                }

                let p = {
                    first: {
                        tY: pg.y - pg.xGap,
                        lX: pg.x + this.rate,
                        bY: pg.y - pg.xGap + this.height - this.rate,
                        rX: pg.x + this.width - this.rate,
                    },
                    main: {
                        tY: pg.y,
                        lX: pg.x + this.rate,
                        bY: pg.y + this.height - this.rate, // 350 + 72 - 10 = 412
                        rX: pg.x + this.width - this.rate,
                    },
                    second: {
                        tY: pg.y + pg.xGap,
                        lX: pg.x + this.rate,
                        bY: pg.y + pg.xGap + this.height - this.rate,
                        rX: pg.x + this.width - this.rate,
                    },
                    last: {
                        tY: pg.y + pg.xGap*2,
                        lX: pg.x + this.rate,
                        bY: pg.y + pg.xGap*2 + this.height - this.rate,
                        rX: pg.x + this.width - this.rate,
                    }
                }

                /** двигаем все джойстики */
                pg.x -= this.dx;

                if(pg.x < -this.w) {
                    this.pipeGenerator.shift()
                    /** удаляем проехавших */
                    /** score up */
                    scoreUp && time === 0 && scoreUp()

                }

                if (
                    p.main.bY > bird.topY + this.rate   &&
                    p.main.bY < bird.bottomY - this.rate   &&
                    p.main.lX < bird.rightX &&
                    p.main.lX > bird.leftX
                ) {
                    console.log('game over - main block bottom')
                    gameOver && gameOver(time !== 0)

                }

                if (
                    p.main.tY > bird.topY + this.rate   &&
                    p.main.tY < bird.bottomY - this.rate   &&
                    p.main.lX < bird.rightX &&
                    p.main.lX > bird.leftX
                ) {
                    console.log('game over - main block top')
                    gameOver && gameOver(time !== 0)
                }

                if (
                    p.second.bY > bird.topY + this.rate   &&
                    p.second.bY < bird.bottomY - this.rate   &&
                    p.second.lX < bird.rightX &&
                    p.second.lX > bird.leftX
                ) {
                    console.log('game over - second block bottom')
                    gameOver && gameOver(time !== 0)
                }

                if (
                    p.second.tY > bird.topY + this.rate   &&
                    p.second.tY < bird.bottomY - this.rate   &&
                    p.second.lX < bird.rightX &&
                    p.second.lX > bird.leftX
                ) {
                    console.log('game over - second block top')
                    gameOver && gameOver(time !== 0)
                }

                if (
                    p.first.bY > bird.topY + this.rate   &&
                    p.first.bY < bird.bottomY - this.rate   &&
                    p.first.lX < bird.rightX &&
                    p.first.lX > bird.leftX
                ) {
                    console.log('game over - first block bottom')
                    gameOver && gameOver(time !== 0)
                }

                if (
                    p.first.tY > bird.topY + this.rate   &&
                    p.first.tY < bird.bottomY - this.rate   &&
                    p.first.lX < bird.rightX &&
                    p.first.lX > bird.leftX
                ) {
                    console.log('game over - first block top')
                    gameOver && gameOver(time !== 0)
                }
            }
        }
    }
}