import React, {FC} from "react";
import './button.scss';
interface IButtonProps {
    disabled?: boolean;
    borderMain?: boolean;
    borderWhite?: boolean;
    hoverContent?: React.ReactNode;
    onClick(): void;
}

const Button: FC<IButtonProps> = (props) => {
    const handleClick = () => {
        if (props.disabled) {
            return;
        }

        props.onClick();
    }

    let classButton = 'button';

    if (props.borderMain) {
        classButton += ' button--main'
    }

    if (props.borderWhite) {
        classButton += ' button--white'
    }

    if (props.hoverContent) {
        return (
            <div className={'button__center'}>
                <div className={classButton} onClick={handleClick}>
                    <div className="button__content button__content--def">{props.children}</div>
                    <div className="button__content button__content--hover">{props.hoverContent}</div>
                </div>
            </div>
        );
    }

    return (
        <div className={'button__center'}>
            <div className={classButton} onClick={handleClick}>
                <div className="button__content">{props.children}</div>
            </div>
        </div>

    );

}

export default Button;