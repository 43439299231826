import React, {FC, useEffect} from "react";
import Button from "../button";
import {getFiveNumbers} from "../../shared/utils/utils";

interface IGOProps {
    count: number;
    lives: number;
    onNext(): void;
}

const GameOver: FC<IGOProps> = (props) => {
    useEffect(() => {
        document.addEventListener('keypress', handleStart);

        return () => {
            document.removeEventListener('keypress', handleStart);
        }
    }, []);


    const handleStart = (event: any) => {
        if (event.key === 'Enter') {
            setTimeout(() => {
                props.onNext()
            }, 500);
        }
    }

    return (
        <div className="game__layout game__layout--top">
            <div className="game__container">
                <div className="game__croky" />
                <div className="game__cry-bird" />
                <div className="game__over">{getFiveNumbers(props.count)}</div>
                <Button borderMain onClick={props.onNext}>
                    {props.lives === 1 ? 'start pagina' : 'opnieuw proberen'}
                </Button>
            </div>
        </div>
    );
}

export default GameOver;