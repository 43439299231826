export enum Lang {
    NL = 'nl',
    FR = 'fr',
}

export enum Pages {
    start = 'start',
    game = 'game',
}

export enum gameState {
    instruction,
    ready,
    playWithoutGravity,
    play,
    gameOver,
}
