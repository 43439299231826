import {useState} from "react";
import {Lang, Pages} from "./constants";


export const useGame = () => {

    /** ------------------------- state ----------------------------- **/
    const lang = Lang.NL;
    const [page, setPage] = useState<Pages>(Pages.start);
    const [lives, setLives] = useState(3);

    return {
        state: {
            page,
            lang,
            lives,
        },
        setPage,
        setLives,
    }
}