export const getFiveNumbers = (count: number): string => {
    const countZero = 5 - `${count}`.length; // 2
    let zeros = '';
    for(let i=0; i<countZero; i++) {
        zeros += '0';
    }

    return `${zeros}${count}`;
}

export const randomize = (min: number, max: number): number => {
    return min + Math.floor(Math.random() * (max - min) + 1)
}

